import React from 'react';
import Slideshow from '../components/Slideshow';
import '../css/styles.css';
import FeatureBlock from '../components/FeatureBlock';

const Home = () => {
  return (
    <div className="home-container">
      <Slideshow />
      <div className="features-container">
      <FeatureBlock
        title="Gallery"
        description="Explore our curated collection of artworks."
        buttonText1="Explore Gallery"
        image="/assets/bowls_vases/image6.jpg"
        className="right-aligned"
        linkTo={'gallery'}
      />
      <FeatureBlock
        title="Motorcycle Art"
        description="Discover art inspired by the thrill of motorcycles."
        buttonText1="Discover More"
        image="/assets/motorcycle_art/Gilroy/Frosted.jpg"
        className="left-aligned"
        linkTo={'motorcycle_art'}
      />
      <FeatureBlock
        title="About"
        description="Learn more about our mission and vision."
        buttonText1="Read More"
        image="/assets/general/about.JPG"
        className="right-aligned"
        linkTo={'about'}
      />
      <FeatureBlock
        title="Contact"
        description="Have questions? Reach out to us."
        buttonText1="Contact Us"
        image="/assets/general/contact.jpg"
        className="left-aligned"
        linkTo={'contact'}
      />
      </div>
    </div>
  );
};

export default Home;
