import React from 'react';
import '../css/styles.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>ABOUT HOWARD HEGWER</h1>
      <p>
        Hello, I’m Howard Hegwer a glass artist living in the Olympia, Washington area. After working in a variety of other media I finally discovered glass. I knew that the dynamic nature, fluidity and multi-dimensional aspect of molten glass would provide the artistic challenges and rewards for which I had been searching.
      </p>
      <p>
        I am pleased to share this art work with you – whether as a functional adornment for your motorcycle, a business gift of original art or glass art for your home, my goal is to provide accessible art which balanced the delicacy of glass with the functionality of such a ubiquitous material.
      </p>
    </div>
  );
};

export default About;
