import React, { useState } from 'react';
import '../css/styles.css';

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="/" className="company-title">
          <span>Howard Hegwer</span>
          <span>Designs</span>
        </a>
      </div>
      <button className="hamburger" onClick={toggleNav}>
        {/* Hamburger icon lines */}
        <span></span>
        <span></span>
        <span></span>
      </button>
      <nav className={`navigation ${isNavVisible ? 'visible' : ''}`}>
        <ul>
          <li><a href="/gallery">Gallery</a></li>
          <li><a href="/motorcycle_art">Motorcycle Art</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
