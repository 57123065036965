import React from 'react';
import { Link } from 'react-router-dom';
import '../css/styles.css';

function TitleCard({ name, coverImage, linkTo }) {
    return (
        <Link to={linkTo} className="TitleCard">
            <img src={coverImage} alt={`${name} Cover`} className="category-image" />
            <p className="category-title">{name}</p>
        </Link>
    );
}

export default TitleCard;
