import React from 'react';
import { Link } from 'react-router-dom'; // 1. Import Link
import '../css/styles.css';

const FeatureBlock = ({ title, description, buttonText1, image, className, linkTo }) => { // 2. Add linkTo prop
    return (
        <div className={`feature-block ${className}`}>
            <div className="feature-content">
                <h2>{title}</h2>
                <p>{description}</p>
                <div className="buttons">
                    <Link to={linkTo}> {/* 3. Wrap the button with Link */}
                        <button className="btn-primary">{buttonText1}</button>
                    </Link>
                </div>
            </div>
            <div className="feature-image">
                <img src={image} alt={title} />
            </div>
        </div>
    );
}

export default FeatureBlock;