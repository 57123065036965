import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import TypeGallery from './components/Type_Gallery';
import MotorcycleArt from './pages/Motorcycle_Art';
import TypeMotorcycle from './components/Type_MotorCycle';

import './css/styles.css';
import About from './pages/About';
import ScrollToTop from './components/FunctionalComponents';  // Make sure the path is correct

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:type" element={<TypeGallery />} />
          <Route path="/motorcycle_art" element={<MotorcycleArt />} />
          <Route path="/motorcycle_art/:type" element={<TypeMotorcycle />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
