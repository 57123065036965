import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/styles.css';

const ShoppingCard = ({ src, alt, title, price, routePath }) => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  return (
    <div className="product-card">
      <div className="image-container">
        <img src={src} alt={alt} className="card-image" />
      </div>
        <div className="product-stats-wrapper">
          <p className="product-price">${price}</p>
          <input 
            type="number" 
            value={quantity} 
            onChange={(e) => setQuantity(e.target.value)} 
            className="quantity-box"
          />
        </div>
        <div className="card-buttons">
          <button className="add-to-cart">Add to Cart</button>
          <button onClick={() => navigate(routePath)} className="view-btn">View</button>
        </div>
    </div>
  );
};

export default ShoppingCard;
