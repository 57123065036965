import React from 'react';
import '../css/styles.css';
import FeatureBlock from '../components/FeatureBlock';
import TitleCard from '../components/TitleCard';

const categoryData = [
    {
      name: 'Gilroy',
      coverImage: 'assets/motorcycle_art/Gilroy/Gilroy.jpg',
      description: "The Gilroy models represent a unique chapter in the Indian Motorcycle history. Produced in Gilroy, California, these bikes retained the iconic style of Indian Motorcycles while introducing modern engineering and design innovations. A blend of classic and contemporary, the Gilroy models are a testament to Indian's resilience and adaptability."
    },
    {
      name: '2007 & Newer',
      coverImage: 'assets/motorcycle_art/2007 & Newer/2007.jpg',
      description: "Indian Motorcycles' 2007 and newer models signify a modern era for the brand. Boasting advanced technology, refined engineering, and state-of-the-art design elements, these motorcycles cater to the new generation while paying homage to the brand's rich heritage. Experience the power, performance, and prestige of Indian's contemporary line-up."
    },
    {
      name: 'Holiday Ornaments',
      coverImage: 'assets/motorcycle_art/Holiday_Ornaments/Clear.jpg',
      description: "Celebrate the festive season with our special edition Holiday Ornaments. Intricately designed and inspired by the legacy of Indian Motorcycles, these ornaments are perfect for adding a touch of motorcycling spirit to your holiday decorations."
    },
  ];
  

  const MotorcycleArt = () => {
    return (
      <>
        <div className="motorcycle-cta">
          <h1>Discover Motorcycle Artistry</h1>
          <p>Dive into the world of Howard Hegwer's motorcycle-inspired creations. Select a model to view its unique artistic representation.</p>
        </div>
        <div className="motorcycle-container">
          {categoryData.map((category, index) => (
          <TitleCard 
            key={index} 
            name={category.name}
            coverImage={category.coverImage}
            linkTo={`/motorcycle_art/${category.name}`}
          />
          ))}
        </div>
        <div className="motorcycle-learn-more">
          <h2>Need guidance?</h2>
          <p>Learn more about the inspirations and stories behind each Indian Motorcycle model artwork.</p>
        </div>
        <div className="features-container">
          {categoryData.map((category, index) => (
            <FeatureBlock
              key={index}
              title={category.name}
              description={category.description}
              buttonText1="Explore Artwork"
              image={category.coverImage}
              className={index % 2 === 0 ? "right-aligned" : "left-aligned"}
              linkTo={`/motorcycle_art/${category.name}`}
            />
          ))}
        </div>
      </>
    );
  };
  

export default MotorcycleArt;
