import React from 'react';
import { useParams } from 'react-router-dom';
import ShoppingCard from './ShoppingCard'; // Assuming the file is in the same directory; adjust the path if necessary
import '../css/styles.css';

const TypeMotorcycle = () => {
  const { type } = useParams();
  
  // Sample data for the ShoppingCards
  const sampleData = [
    { src: `/assets/motorcycle_art/${type}/Amber.jpg`, alt: `Amber - ${type}`, title: `Amber`, price: '10.99', routePath: `Amber` },
    { src: `/assets/motorcycle_art/${type}/Blue.jpg`, alt: `Blue - ${type}`, title: `Blue`, price: '10.99', routePath: `Blue` },
    { src: `/assets/motorcycle_art/${type}/Frosted.jpg`, alt: `Frosted - ${type}`, title: `Frosted`, price: '10.99', routePath: `Frosted` },
    { src: `/assets/motorcycle_art/${type}/Red.jpg`, alt: `Red - ${type}`, title: `Red`, price: '10.99', routePath: `Red` },
    // ... Add more data if necessary
];

  return (
    <div className="type-motorcycle-container">
      <h2 className="type-motorcycle-header">{type} Motorcycle Art</h2>
      <div className="cards-grid">
        {/* Use ShoppingCard component */}
        {sampleData.map((data, index) => (
          <div className="card-wrapper" key={index}>
            <ShoppingCard 
              src={data.src}
              alt={data.alt}
              title={data.title}
              price={data.price}
              routePath={data.routePath}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeMotorcycle;
