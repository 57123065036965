import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/styles.css';

const Slideshow = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  };

  return (
    <div className="slideshow-container">
      <Slider {...settings}>
        <div>
          <img src="/assets/slideshow/image1.jpg" alt="Slide 1" />
        </div>
        <div>
          <img src="/assets/slideshow/image2.jpg" alt="Slide 2" />
        </div>
        <div>
          <img src="/assets/slideshow/image3.jpg" alt="Slide 3" />
        </div>
      </Slider>
    </div>
  );
};

export default Slideshow;
