import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../css/styles.css';

const TypeGallery = () => {
  const { type } = useParams();
  const [numPlaceholders, setNumPlaceholders] = useState(0);

  useEffect(() => {
    const placeholdersForType = {
      'Castings': 6,
      'Sculptures': 8,
      'Bowls & Vases': 5,
      // ... other categories
    };

    setNumPlaceholders(placeholdersForType[type] || 5);
  }, [type]);

  return (
    <div className="type-gallery-container">
      <h2 className="type-gallery-header">{type}</h2>
      <div className="cards-container"> {/* New container for cards */}
        {Array.from({ length: numPlaceholders }).map((_, index) => (
          <div key={index} className="card">
            <div className="placeholder"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeGallery;

