import React from 'react';
import '../css/styles.css';
import FeatureBlock from '../components/FeatureBlock';
import TitleCard from '../components/TitleCard';

const categoryData = [
  {
    name: 'Castings',
    coverImage: 'assets/castings/image2.jpg',
    description: "Castings involve pouring molten glass into molds. This technique allows for a variety of shapes and textures. The result is often a piece that feels tactile and looks captivating, showcasing the versatility of glass as a medium."
  },
  {
    name: 'Sculptures',
    coverImage: 'assets/sculptures/image15.jpg',
    description: "Glass sculptures can be both realistic or abstract. Made by shaping molten glass, these pieces can be intricate or simple, but always serve as a statement of artistry and creativity."
  },
  {
    name: 'Bowls & Vases',
    coverImage: 'assets/bowls_vases/image7.jpg',
    description: "Bowls and vases are versatile pieces crafted from glass. Bowls, with their wide openings, are great for holding various items, while vases are typically taller and ideal for displaying flowers. Both can add a touch of color and style to any room."
  },
  {
    name: 'Long Neck',
    coverImage: 'assets/long_neck/image11.jpg',
    description: "Long neck glass pieces are known for their extended, slender necks. Their unique shape captures attention, and they're often used as decorative pieces. The elongated design enhances the beauty of the glass colors and patterns."
  },
  {
    name: 'Seasonal',
    coverImage: 'assets/seasonal/image31.JPEG',
    description: "Description for Seasonal category"
  }
];

const Gallery = () => {
  return (
    <>
      <div className="gallery-cta">
        <h1>Welcome to the Gallery</h1>
        <p>Explore Howard Hegwer's exquisite collections. Click on a category to delve deeper.</p>
      </div>
      <div className="gallery-container">
        {categoryData.map((category, index) => (
        <TitleCard 
          key={index} 
          name={category.name}
          coverImage={category.coverImage}
          linkTo={`/gallery/${category.name}`}
        />
        ))}
      </div>
      <div className="gallery-learn-more">
        <h2>Learn More</h2>
        <p>Read about different glassworks</p>
      </div>
      <div className="features-container">
        {categoryData.map((category, index) => (
          <FeatureBlock
            key={index}
            title={category.name}
            description={category.description}
            buttonText1="See More"
            image={category.coverImage}
            className={index % 2 === 0 ? "right-aligned" : "left-aligned"}
            linkTo={`/gallery/${category.name}`}
          />
        ))}
      </div>
    </>
  );
};

export default Gallery;
